$(function () {
    /**
     * sidr initialization
     * navbar for mobile
     */
    $('#menu-toggle').sidr({
        name: 'sidr-off-canvas',
        side: 'right',
        source: '.main-navigation'
    });


    /**
     * scroll to top
     */
    var didScroll = false,
        $arrow = $('#back-to-top');

    $arrow.click(function (e) {
        $('body,html').animate({scrollTop: "0"}, 750);
        e.preventDefault();
    });

    $(window).scroll(function () {
        didScroll = true;
    });

    setInterval(function () {
        if (didScroll) {
            didScroll = false;

            if ($(window).scrollTop() > 200) {
                $arrow.fadeIn();
            } else {
                $arrow.fadeOut();
            }
        }
    }, 250);


    /**
     * bootstrap tooltip
     */
    $('[rel^=tooltip]').tooltip();
    $('[data-toggle="tooltip"]').tooltip();

    /**
     * initialize superfish menu, our main navbar
     */
    $('.sf-menu').superfish({
        delay: 200,
        animation: {opacity: 'show', height: 'show'},
        speed: 'fast',
        cssArrows: false,
        disableHI: true
    });
    $(".main-navigation > ul > li:has(ul)").find("a:first").append(" <i class='fa fa-angle-down'></i>");
    $(".main-navigation > ul > li > ul > li:has(ul)").find("a:first").append(" <i class='fa fa-angle-right'></i>");
    $(".main-navigation > ul > li > ul > li > ul > li:has(ul)").find("a:first").append(" <i class='fa fa-angle-right'></i>");

    // Centering the dropdown menus
    $(".main-navigation ul li").mouseover(function () {
        var the_width = $(this).find("a").width();
        var child_width = $(this).find("ul").width();
        var width = parseInt((child_width - the_width) / 2);
        $(this).find("ul").css('left', -width);
    });

    /**
     * sticky nav
     */
    if ($(window).width() > 992) {
        $(".lower-header").sticky({topSpacing: 0});
    }

    /**
     * navbar countdown
     */
    // FrontPage Time Counter
    var counter = $('#counter');
    var expiryDate = counter.data('date');
    var target = new Date(expiryDate),
        finished = false;

    function callback(event) {
        var $this = $(this);
        switch (event.type) {
            case "seconds":
            case "minutes":
            case "hours":
            case "days":
            case "weeks":
            case "daysLeft":
                $this.find('div span#' + event.type).html(event.value);
                if (finished) {
                    $this.fadeTo(0, 1);
                    finished = false;
                }

                break;
            case "finished":
                $this.fadeTo('slow', .5);
                finished = true;
                break;
        }
    }

    counter.countdown(target.valueOf(), callback);

    /**
     * misc
     */
    // Heading Styles
    $(".title").each(function () {
        $(this).wrapInner("<div class='title-border'></div>");
    });

    // fix featured projects width
    var width = $(".featured-project-block").width();
    $(".project-overlay").css("width", width);
});