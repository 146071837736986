import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static values = {
        utcOffset: Number
    };

    static targets = ["liveTime"];

    connect() {
        this.updateTime = this.updateTime.bind(this);
        this.updateTime();

        this.interval = setInterval(this.updateTime, 1000);
    }

    disconnect() {
        clearInterval(this.interval);
    }

    updateTime() {
        const now = new Date();

        const utcTime = now.getTime() + (now.getTimezoneOffset() * 60000);
        const cityTime = new Date(utcTime + this.utcOffsetValue * 1000);


        const hours = cityTime.getHours().toString().padStart(2, "0");
        const minutes = cityTime.getMinutes().toString().padStart(2, "0");
        const seconds = cityTime.getSeconds().toString().padStart(2, "0");

        const currentTime = `${hours}:${minutes}:${seconds}`;


        this.liveTimeTarget.textContent = currentTime;
    }
}
