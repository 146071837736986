import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    calculate() {
        let date = new Date(this.element.querySelector('#start_date').value);
        let alternativeDate = new Date(this.element.querySelector('#start_date').value);
        const addOrSubtract = this.element.querySelector('#add_or_subtract').value;
        const days = this.findElementAndSafelyParseInt('days');
        const weeks = this.findElementAndSafelyParseInt('weeks');
        const months = this.findElementAndSafelyParseInt('months');
        const years = this.findElementAndSafelyParseInt('years');

        const resultWrapper = this.element.querySelector('#dates_calculator_result_wrapper');

        if (addOrSubtract === 'Minus') {
            date.setMonth(date.getMonth() - months);
            date.setFullYear(date.getFullYear() - years);
            date.setDate(date.getDate() - days - (7 * weeks));
            alternativeDate.setDate(alternativeDate.getDate() - days - (7 * weeks));
            alternativeDate.setMonth(alternativeDate.getMonth() - months);
            alternativeDate.setFullYear(alternativeDate.getFullYear() - years);
        } else {
            date.setMonth(date.getMonth() + months);
            date.setFullYear(date.getFullYear() + years);
            date.setDate(date.getDate() + days + (7 * weeks));
            alternativeDate.setDate(alternativeDate.getDate() + days + (7 * weeks));
            alternativeDate.setMonth(alternativeDate.getMonth() + months);
            alternativeDate.setFullYear(alternativeDate.getFullYear() + years);
        }

        const formattedDate = this.formatDate(date);
        const formattedAlternativeDate = this.formatDate(alternativeDate);

        if (formattedDate === formattedAlternativeDate) {
            resultWrapper.innerHTML = `
                <h3>
                    <strong>
                        ${formattedDate}
                    </strong>
                </h3>
            `;
        } else {
            resultWrapper.innerHTML = `
                <h3>
                    <strong>
                       Zwei Ergebnisse möglich:
                    </strong>
                </h3>
                <div class="mb1">
                    <p class="mb0"><strong>${formattedDate}</strong></p>
                    <i class="mb4">
                        Die Monate und Jahre wurden vor den 
                        Tagen und Wochen addiert bzw. subtrahiert.
                    </i>
                </div>
                <div>
                    <p class="mb0"><strong>${formattedAlternativeDate}</strong></p>
                    <i class="mb1">
                        Die Tage und Wochen wurden vor den 
                        Monaten und Jahren addiert bzw. subtrahiert.
                    </i>
                </div>
            `
        }

        console.log(date.getDate() === alternativeDate.getDate());
    }

    findElementAndSafelyParseInt(string) {
        const number = parseInt(this.element.querySelector(`#${string}`).value);
        if (isNaN(number)) {
            return 0
        } else {
            return number
        }
    }

    formatDate(date) {
        const monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni",
            "Juli", "August", "September", "Oktober", "November", "Dezember"];

        const day = String(date.getDate()).padStart(2, '0');
        const monthIndex = date.getMonth();
        const monthName = monthNames[monthIndex];
        const year = date.getFullYear();
        const weekday = date.toLocaleDateString("de-DE", {weekday: 'long'});

        return `${weekday}, ${day}. ${monthName} ${year}`;
    }
}
