import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        this._checkGtagFunction();
    }

    grant(_event) {
        gtag('consent', 'update', {
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'ad_storage': 'granted',
            'analytics_storage': 'granted'
        })
    }

    deny(_event) {
        gtag('consent', 'update', {
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'ad_storage': 'denied',
            'analytics_storage': 'denied'
        })
    }

    _checkGtagFunction() {
        if (typeof gtag === 'undefined') {
            window.dataLayer = window.dataLayer || [];
            window.gtag = function () {
                dataLayer.push(arguments)
            }
        }
    }
}
